import React, { useState, useEffect, useRef } from "react"
import { graphql } from "gatsby"
import PhoneInput from 'react-phone-input-2'
import Layout from "../components/layout/layout2"
import Meta from "../components/addon/meta"
import toast from "react-hot-toast"
import Schema from "../components/addon/schema"
import useScript from "../service/useScript"
import SimpleReactValidator from "simple-react-validator"
import UseCustomValidator from "../service/simpleValidator"
import APIButton from "../components/addon/button/apiButton"
import UPLOAD_API from "../api/postings"
import Thankyou from '../components/addon/thankYou'

import 'react-phone-input-2/lib/style.css'

const Prescriptionupload = (props) => {
    const [loading, setLoading] = useState(false)
    const [, forceUpdateForRegister] = useState();
    const [aadharImg, setAadharImg] = useState(null);
    const [fileError, setFileError] = useState('')
    const [thankYou, setThankYou] = useState(false)
    const { customMobileValidator } = UseCustomValidator()

    const metaTags = {}
    const schema = ''
    const data = props?.data?.nodeTemplatePages;
    console.log('dddddd',props)
    const pageComponents = data?.relationships?.field_component_type

    let aadharRegex =/^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/;

    const mobileValidator = customMobileValidator

    const [personalDetailValidator] = useState(useRef(new SimpleReactValidator({
        element: (message, className) => <p className="field_error" > {message} </p>,
        validators: {
            validateAadhar: {
                message: 'The :attribute should be a valid aadhar no.',
                rule: (val, params, validator) => {
                    if(aadharRegex.test(val)) {
                        return val
                    } else {
                        return false
                    }
                },
                messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)),  // optional
                required: false  // optional
            },
        }
    })))

    const thankyouScroll = useRef()

    let regForm = { webform_id: 'prescirption', first_name: '', last_name: '', mobile_no: '', email_address: '', comments: '', upload_report: ''}
    const [registerForm, setRegisterForm] = useState(regForm);


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setRegisterForm({
            ...registerForm,
            [name]: value,
        });
    };

    
    const handleSelectChange = (e) => {
      // const val = e?.label
      // const contryPrefix = e?.country_prefix?.toLowerCase()
      // setRegisterForm({
      //     ...registerForm,
      //     'country_name': val,
      //     'country_code': e?.value
      // });
      // setDailCode(contryPrefix)
    };

const removeImg = () => {
  setAadharImg(null)
  const el = document.getElementById('reg_img_upload')
  if(el?.value) el.value = ''
}

const handleImgUpload = async (e) => {
  let fileName =  e.target.value.toLowerCase()
  let file = e.target.files[0]
  if (file && fileName.includes('.jpg') 
      || file && fileName.includes('.png') 
      || file && fileName.includes('.pdf')
      || file && fileName.includes('.jpeg')) {
      setFileError("");
      setAadharImg(file)
      //uploadImage()
      if(file){
        uploadImage(file)
      }
      console.log('aadharImg====',file)
  } else {
      setFileError("Please upload your Img in .jpg or .png or pdf format")
  }
}


const uploadImage = async(file) => {
  setLoading(true)
  const res = await UPLOAD_API.submitImage(file)
    .catch(err => {
        console.log(err)
        toast.error("Image Upload Failed.")
        setLoading(false)
        return
    })
    let imgData = {}
    if(res?.fid && res?.fid[0]?.value) {
        imgData = {
            fid: res?.fid[0]?.value
        }
        setRegisterForm({
          ...registerForm,
          upload_report: imgData?.fid
        })
        setLoading(false)
    } else {
        setLoading(false)
        return
    }
}

    const submitRegistrationForm = async () => {
        setLoading(true)
        const formResult = personalDetailValidator.current.allValid()
        const mobileResult = mobileValidator.current.allValid()
        if (formResult === false) {
            personalDetailValidator.current.showMessages()
            forceUpdateForRegister(1)
        }
        
        if (mobileResult === false) {
            mobileValidator.current.showMessages()
            forceUpdateForRegister(2)
        }

        if (formResult && mobileResult) {
            const response = await UPLOAD_API.submitFormData(registerForm)
            .catch(err => {
                console.log(err)
                toast.error("Looks like something went wrong. Please try again later.")
            })
            if (response) {
                setThankYou(true);
                thankyouScroll.current.scrollIntoView({ behavior: 'smooth' })
                // resetForm();
                //window.scroll({ top: 0, behavior: "smooth" })
            }else{
                toast.error("Something went wrong.")
                setLoading(false)
            }
        }else{
          setLoading(false)
        }
    }

  const resetThankyou = (value) => {
    if(window) window.location.reload();
  }

    useScript("/assets/js/latest-technology-slider.js")
    useScript("/assets/js/custom_select.js");
    return (
        <>
            <Layout>
                <Meta
                    files={{
                        js: [],
                        css: ["/assets/css/room-type.css", "/assets/css/testimonial.css", "/assets/css/about-through-the-ages.css", "/assets/css/prescription-header.css"],
                    }}
                    tags={metaTags}
                />
                <Schema schema={schema} />
                <main className="innerpage">
                    {
                        pageComponents?.map( item => {
                            if(item?.paragraph_type?.drupal_internal__target_id === "banner"){
                                return(
                                    <section className="inner_common_banner">
                                        <div className="banner_img">
                                            {/* <source media="(min-width:1024px)"
                                                srcset={item?.relationships?.field_banner_image?.uri?.url || ''} />
                                            <source media="(min-width:300px)"
                                                srcset={item?.relationships?.field_mobile_banner?.uri?.url || ''} /> */}
                                            {
                                              item?.relationships?.field_mobile_banner && item?.relationships?.field_mobile_banner?.uri?.url !== '' && <img className="d-md-none" src={item?.relationships?.field_mobile_banner?.uri?.url || ''} alt={item?.field_title || ''} title={item?.field_title || ''} />
                                            }
                                            <img className="d-md-block d-none" src={item?.relationships?.field_banner_image?.uri?.url || ''} alt={item?.field_title || ''} title={item?.field_title || ''} />
                                        </div>
                                        <div className="banner_content text_left text_dark">
                                            <div className="container">
                                                <h1 dangerouslySetInnerHTML={{__html: item?.field_title || ''}}></h1>
                                                {/* <p dangerouslySetInnerHTML={{ __html: item?.field_subtitle }}></p> */}
                                                <div dangerouslySetInnerHTML={{ __html: item?.field_description?.processed}}></div>
                                            </div>
                                        </div>
                                    </section>
                                )
                            }
                        })
                    }

                    {
                      thankYou ? <Thankyou ref={thankyouScroll} setThankYou={resetThankyou} /> : (
                        <>
                          <section className="section-bg section-py">
                            {   pageComponents.length > 0 && pageComponents[1] &&
                                <div className="container">
                                    <div className="pb-3">
                                        <h3 className="pb-3">{pageComponents[1]?.field_title}</h3>
                                        <div dangerouslySetInnerHTML={{ __html: pageComponents[1]?.field_description?.processed}}></div>
                                    </div>
                                    <div className="white_rounded_box">
                                        <div className="report_upload_section">
                                            <div className="form_wrap international_form_wrap" id="report_upload_wrap">
                                                <div className="form_block pl-0">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="row">
                                                                <div className="col-12 col-md-6 col-lg-4">
                                                                    <div className="form-group -animated">
                                                                    <label>First Name <span className="mandatory">*</span></label>
                                                                        <input
                                                                            onChange={handleInputChange}
                                                                            id="first_name"
                                                                            name="first_name"
                                                                            disabled={false}
                                                                            value={registerForm.first_name}
                                                                            className="form-control"
                                                                            type="text"
                                                                            autoComplete="nope"
                                                                            />
                                                                        {personalDetailValidator.current.message('FirstName', registerForm.first_name, 'required|alpha_space')}
                                                                    </div>
                                                                </div>

                                                                <div className="col-12 col-md-6 col-lg-4">
                                                                    <div className="form-group -animated">
                                                                        <label>Last Name <span className="mandatory">*</span></label>
                                                                        <input onChange={handleInputChange} id="last_name" disabled={false} name="last_name" value={registerForm.last_name} className="form-control" type="text" autoComplete="nope" />
                                                                        {personalDetailValidator.current.message('LastName', registerForm.last_name, 'required|alpha')}
                                                                    </div>
                                                                </div>

                                                                <div className="col-12 col-md-6 col-lg-4">
                                                                    <div className="form-group -animated">
                                                                      <label>Mobile No. <span className="mandatory">*</span></label>
                                                                      <input onChange={handleInputChange} value={registerForm.mobile_no} name="mobile_no" className="form-control" type="text" maxLength={10} autoComplete="nope" />
                                                                      {mobileValidator.current.message('MobileNo', registerForm.mobile_no, 'min:10|max:10|required|validateMobile')}
                                                                    </div>
                                                                    {/* <div className={`form-group -animated -active`}>
                                                                      <label>Mobile <span className="mandatory">*</span></label>
                                                                      { console.log('registerForm====>>>',registerForm)}
                                                                      <PhoneInput
                                                                          className="mt-2"
                                                                          autoComplete={'off'}
                                                                          preferredCountries={countryDialCodes}
                                                                          country={dailCode}
                                                                          value={registerForm.MobileExt}
                                                                          countryCodeEditable={false}
                                                                          disabled={verifyOpen}
                                                                          placeholder={''}
                                                                          onKeyDown={(value, country, e, formattedValue) => {
                                                                            setRegisterForm({
                                                                                  ...registerForm,
                                                                                  ['MobileExt'] : country?.dialCode,
                                                                                  ['mobile_no'] : value.length ? value.substring(country?.dialCode?.length) : ''
                                                                              })
                                                                          }}
                                                                          inputProps={{
                                                                              name: 'mobile_no',
                                                                              required: true,
                                                                          }}
                                                                      />
                                                                      {mobileValidator.current.message('Mobile ', registerForm.mobile_no , 'required|validateMobile')}
                                                                      {!verifyOpen && <LinkButton title={`Verify with OTP`} onBtnClick={onVerify} loading={otpLoading} />}
                                                                    </div> */}
                                                                </div>

                                                                <div className="col-12 col-md-6 col-lg-4">
                                                                    <div className="form-group -animated">
                                                                      <label>Email Address</label>
                                                                      <input onChange={handleInputChange} autoComplete={'off'} name="email_address" value={registerForm.email_address} className="form-control" type="text" />
                                                                      {personalDetailValidator.current.message('Email', registerForm.email_address, 'email')}
                                                                    </div>
                                                                </div>
                                                                
                                                                <div className="col-12 col-md-12 col-lg-8 uploadMainSecWrap">
                                                                        <div className="form-group upload-file -animated">
                                                                            <label>Upload Prescription <span className="mandatory">*</span></label>
                                                                            <div className="input-group">
                                                                              <input type="text" className="form-control" readonly />
                                                                              <div className="input-group-btn">
                                                                                  <span className="fileUpload">
                                                                                      <span className="upl upload" id="upload">{aadharImg ? 'Uploaded' : 'Upload'} </span>
                                                                                      {aadharImg ? <a className="remove otp_color" id="upload" onClick={() => removeImg()}>Remove</a> : null}
                                                                                      <input id='reg_img_upload' name="resume" className="form-control" type="file" onChange={handleImgUpload} />
                                                                                  </span>
                                                                              </div>
                                                                            </div>
                                                                        </div>
                                                                        
                                                                    {aadharImg?.name 
                                                                      ? <div className="notes border-0 mb-2">
                                                                          <ul>
                                                                              <li>{aadharImg?.name}</li>
                                                                          </ul>
                                                                      </div>
                                                                      : null}
                                                                    <div className="mb-2">
                                                                        <div className="notes">
                                                                            <ul>
                                                                                <li>Make sure that:</li>
                                                                                <li>1. The image is not blurry.</li>
                                                                                <li>2. All the details are clearly visible.</li>
                                                                                <li>3. Image should not exceed 2 MB.</li>
                                                                                <li>4. Only .jpg .png and pdf format permitted.</li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                    {personalDetailValidator.current.message('IdentificationProofImage', aadharImg, 'required')}
                                                                    {fileError.length > 0 ? <p className="field_error">{fileError}</p> : ""}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="form-group textarea">
                                                                <label>Comments </label>
                                                                <textarea value={registerForm.comments} onChange={handleInputChange} name="comments" className="form-control"></textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="row mt-4">
                                                        <div className="col-12">
                                                          <APIButton 
                                                              title={`Submit`} 
                                                              loading={loading}  
                                                              onBtnClick={(e) => !loading ? submitRegistrationForm(e) : {}}  
                                                              className={`btn btn-primary`} 
                                                          />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </section>
                        </>
                      )
                    }
                </main>
            </Layout>
        </>
    )
}

export const query = graphql`
query PrescriptionuploadQuery {
    nodeTemplatePages(path: {alias: {eq: "/prescription-upload"}}) {
      id
      title

      relationships {
        field_breadcrumb {
          field_title
          field_breadcrumb_link {
            title
            uri
          }
        }
        field_component_type {
          ... on paragraph__banner {
            id
            paragraph_type {
              drupal_internal__target_id
            }
            field_title
            field_subtitle
            field_description {
              processed
            }
            field_banner_link {
              title
              uri
            }
            relationships {
              field_banner_image {
                publicUrl
                url
                uri {
                  url
                  value
                }
              }
              field_mobile_banner {
                publicUrl
                url
                uri {
                  url
                  value
                }
              }
            }
          }
          ... on paragraph__html_text {
            id
            paragraph_type {
              drupal_internal__target_id
            }
            field_text {
              processed
            }
          }
          ... on paragraph__tab_components {
            id
            paragraph_type {
              drupal_internal__target_id
            }
            field_title
            relationships {
              field_components {
                ... on paragraph__html_text {
                  id
                  field_text {
                    processed
                  }
                }
                ... on paragraph__image_text {
                  id
                  paragraph_type {
                    drupal_internal__target_id
                  }
                  field_text_demo {
                    processed
                  }
                  relationships {
                    field_image {
                      publicUrl
                      uri {
                        url
                        value
                      }
                      url
                    }
                    field_image_demo {
                      publicUrl
                      uri {
                        url
                        value
                      }
                      url
                    }
                  }
                }
              }
            }
          }
          ... on paragraph__title_and_description {
            id
            paragraph_type {
              drupal_internal__target_id
            }
            field_title
            field_text {
              processed
            }
            field_description {
              processed
            }
            field_cta_link {
              title
              uri
            }
          }
          ... on paragraph__our_specialities_component {
            id
            paragraph_type {
              drupal_internal__target_id
            }
            field_title
            relationships {
              field_component_items {
                field_link {
                  uri
                  title
                }
                field_title
                field_description {
                  processed
                }
                relationships {
                  field_icon {
                    publicUrl
                    uri {
                      url
                      value
                    }
                    url
                  }
                }
              }
            }
          }
          ... on paragraph__book_now_components {
            id
            field_title
            relationships {
              field_component_items {
                field_text {
                  processed
                }
                field_link {
                  title
                  uri
                }
                field_feature
                field_description {
                  processed
                }
                field_cta_link {
                  title
                  uri
                }
                id
                paragraph_type {
                  drupal_internal__target_id
                }
                relationships {
                  field_location_images {
                    publicUrl
                    uri {
                      url
                      value
                    }
                    url
                  }
                }
              }
            }
          }
          ... on paragraph__image_text {
            id
            paragraph_type {
              drupal_internal__target_id
            }
            field_text_demo {
              processed
            }
            relationships {
              field_image {
                publicUrl
                uri {
                  url
                  value
                }
                url
              }
              field_image_demo {
                publicUrl
                uri {
                  url
                  value
                }
                url
              }
            }
          }
          ... on paragraph__section {
            paragraph_type {
              drupal_internal__target_id
            }
            field_name
            field_section_type
            relationships {
              field_components {
                ... on paragraph__title_and_description_components {
                  id
                  field_title
                  field_subtitle
                  field_name
                  field_link {
                    title
                    uri
                  }
                  relationships {
                    field_components {
                      ... on paragraph__image_title_desc_link {
                        id
                        field_title_1
                        field_title
                        field_link {
                          title
                          uri
                        }
                        field_description {
                          processed
                        }
                        relationships {
                          field_image {
                            publicUrl
                            uri {
                              url
                              value
                            }
                            url
                          }
                          field_mobile_image {
                            publicUrl
                            uri {
                              url
                              value
                            }
                            url
                          }
                        }
                      }
                    }
                  }
                }
                ... on paragraph__title_and_description {
                  id
                  field_title
                  field_text {
                    processed
                  }
                  field_description {
                    processed
                  }
                  field_cta_link {
                    title
                    uri
                  }
                  paragraph_type {
                    drupal_internal__target_id
                  }
                }
              }
            }
          }
          ... on paragraph__image_text {
            id
          }
        }
      }
    }
  }
  
fragment ParagraphImageTextWhatsnew on paragraph__image_text {
    id
    text: field_text_demo {
      processed
    }
    image: field_image_demo {
      alt
    }
    relationships {
      image: field_image_demo {
        id
        uri {
          value
          url
        }
      }
      mainimage: field_image {
        id
        uri {
          value
          url
        }
      }
    }
  }  
`
export default Prescriptionupload