/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, Link, graphql } from "gatsby"
import { globalHistory } from "@reach/router"
import LayoutBreadcrumbs from "../addon/layoutBreadcrumbs"
import logo from "../../../static/assets/images/icons/Logo.svg"

const Layout2 = (props) => {
  let [currentYear, setCurrentYear] = useState(null)
  
  const {
    children
  } = props
  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH') {
        if (document.body.classList.contains("search_open")) {
          document.body.classList.remove("search_open")
        }
        if (typeof window.initCommon !== 'undefined') window.initCommon();
      }
    })
  }, [])

  useEffect(() => {
		const date = new Date()
		const year = date.getFullYear()
		setCurrentYear(year)
	}, [])

  const data = useStaticQuery(graphql`
 query SiteTitleQuerySec {    
 site {
 siteMetadata {
 title
 }
 }
 }
 `)
  return (
    <>
      {/* <Header siteTitle={data.site.siteMetadata?.title || `Title`} /> */}
      <header className="sticky-header">
        <nav
          className="navbar navbar-expand-lg flex-wrap p-0"
          aria-label="main navigation"
        >
          <div className="container">
            <Link className="navbar-brand" to="/">
            <img className="lazyload" src={logo} alt="P.D. Hinduja Hospital" width="274" height="31" />
          </Link>
          </div>
          <div className="break"></div>
        </nav>
      </header>
      {children}
      <footer className="copyFooterOnly">
        <div className="copyright">
          <hr />
          <div className="container">
            <p>
              © P. D. Hinduja Hospital. All rights reserved. {currentYear}
            </p>
          </div>
        </div>
        <div
          role="button"
          aria-pressed="false"
          tabIndex="0"
          aria-label="Back to top"
          className="back-to-top"
        >
          <span className="icon"></span>
          <span className="text">Back to top</span>
        </div>
      </footer>
    </>
  )
}
Layout2.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout2